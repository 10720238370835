@use 'variables' as v;

h1, h2, h3 {
    font-family: v.$fuente_headings;
    margin: 0 0 calc(v.$separacion / 2) 0 ;
    font-weight: 400;
}
h1 {
    font-size: 6rem;
}
h2 {
    font-size: 5rem;
}
h3 {
    font-size: 4rem;
}
h4{
    font-size: 3rem;
}
h5{
    font-size: 2rem;
}
p {
    font-size: 2rem;
    color: v.$gris;
    line-height: 1.8;
}