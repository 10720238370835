@use '../base/variables' as v;
@use '../base/mixins' as m;

header{
    nav{
        background: v.$fondoHeader;
        color: v.$blanco;
        font-size: 1.6rem;
    }
    .nav-item {
        font-size: 1.6rem;
        
    }
    .nav-link a{
        display: block;
        height:100%;
        font-size: 1.6rem;
        &:hover{
            background-color: darken(v.$grisOscuro,70%);
        }
    }
    .icono{
        font-size: 1.5rem;
        margin-left: 2rem;
    }
} 
.logoTicket{
	width: 80px;
}
.logo{
    display: none;
}
@include m.tablet {
    .logo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}