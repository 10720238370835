@use '../base/variables' as v;
@use '../base/mixins' as m;

.tabla-docs{
    border: 1px lighten(v.$gris,20) solid;
    width: 100%;
    padding: 1rem;
    border-radius: .5rem;
    border-collapse: collapse;
    font-size: 1rem;
    thead{
        tr{
            background-color: v.$rojo;
            color: v.$blanco;
            padding: 1rem;
        }
        tr{
            text-align: center;
        }
    }
    tbody{
        tr{
            border-bottom: 1px solid lighten(v.$grisClaro,60);
            
        }
        td{
            padding: .5rem 1rem ;
            border: #dddddd 1px solid;
            text-align: center;
        }
    }
    
}
.panel-reservas{
    margin-top: 3rem;
    padding: 1rem;
    overflow-x: auto;
    border: #dddddd solid 1px;
    border-radius: .5rem;
    height: 70vh;
    max-height: 70vh;
}