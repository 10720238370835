@use 'base/variables' as v;
@use 'base/mixins' as m;

.barra-lateral{
    display: flex;
    flex-direction: column;
    background-color: v.$barra-lateral;
    gap: 2rem;
    min-height: 100vh;
    min-width: 30rem;
}
.sidebar__menu{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    a{
        display: flex;
        align-items: center;
        color:v.$blanco;
        text-decoration: none;
        padding: 1rem;
        margin-left: 1rem;
        i{
            font-size: 3rem;
            margin-right: 1rem;
        }
        &:hover{
            background-color: lighten(v.$barra-lateral,10);
        }
    }

}
