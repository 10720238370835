@use 'base/mixins' as m;
@use 'base/variables' as v;

.instructivos{
    display: flex;
    flex-direction: column;
    align-items: center;
    .card{
        margin: 2rem;
    }
    @include m.tablet{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .card{
            max-width: 38rem;
            min-width: 20rem;
        }
    }
    @include m.desktop {
        flex-direction: row;
        align-items: stretch;
        .card{
            width: 38rem;
        }
    }

}
.pdf{
    margin-top: 5.4rem;
}