@use '../base/variables' as v;
@use '../base/mixins' as m;

.dashboard{
    display: flex;
}
.contenedor{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width:100%;
    &__encabezado{
        display: flex;
        flex-direction: column;
    }
    &__usuario-log{
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
            padding: 0;
            font-size: 1.2rem;
            margin-bottom: 0;
        }
        i{
            padding-right: 2rem;
        }

    }
 
}
.content{
    display: flex;
    flex-direction: column;
    width: 100vw;
}