@use '../base/variables' as v;
@use '../base/mixins' as m;

.modal-dialog{
    max-width: 80%;
}
.modal-header {
    font-weight: 700;
}
.modal-content{
    p{
        font-size: 1.8rem;
    }
    li{
        font-size: 1.8rem;
    }
    .modal-body{
        padding: 2rem;
    }
    
}
.modal-footer{
    button[type="button"]{
        font-size: 1.6rem;
    }
}


