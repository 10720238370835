@use '../base/variables' as v;
@use '../base/mixins' as m;

.redes{
	background: v.$fondoFooter;
	ul,svg{
		margin-right: 10px;
	}
	li a{
		font-family: 'Montserrat', sans-serif;
		font-size: 2rem;
		font-weight: 200;
	}
}

