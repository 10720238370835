@use '../base/variables' as v;
@use '../base/mixins' as m;

.menu-body{
    @include m.tablet {
        display:flex;
        flex-flow: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        padding:10rem;
    }
    
    .box{
        width:50rem;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border-radius: .5rem;
        color: v.$blanco;
        &__body{
            display: grid;
            padding: 3rem;
            grid-template-columns: 1fr 2fr;
            i{
                font-size: 10rem;
            }
        }
        &__title{
            font-size: 2rem;
            display: block;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            color: v.$blanco;
        }
        &--fecha{
            background-color: lighten(v.$rosa,30);
        }
        &--documentos{
            background-color: v.$azul;
        }
        &--reservas{
            background-color: v.$indigo;
        }
        &--otras{
            background-color: v.$morado;
        }
        &--usuarios{
            background-color: v.$naranja;
        }
    }
}