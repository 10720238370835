@use '../base/variables' as v;
@use '../base/mixins' as m;

.hidden{
	display: none;
}
.ver{
	display: block;
}
.construccion,
.congelados,
.mantencion,
.perecibles{
	display: none;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 1px lighten(v.$grisClaro, 60%) solid;
	padding: 1rem;
	border-radius: .8rem;
	margin-bottom: 1rem;
	margin-top: 1rem;
	.articulos{
		display: flex;
		width:100%;
		h5{
			display: flex;
			align-items: center;
			padding: 1rem;
			width: 25%;
		}
		label{
			display: flex;
			justify-content: end;
			align-items: center;
			padding: 1rem;
			width: 20%;
		}
		input{
			width: 300px;
		}
	}
	.opcion{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 1rem;
		width: 10%;
	}
	.tipo{
		display: none;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border: 1px lighten(v.$grisClaro, 60%) solid;
		padding: 1rem;
		border-radius: .8rem;
		margin-bottom: 1rem;
		margin-top: 1rem;
	}
}
.mostrarTipo{
	display: flex;
}