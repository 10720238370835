@use 'variables' as v;

*, *:before, *:after {
    box-sizing: inherit;
}
html {
    font-size: 62.5%;
    box-sizing: border-box;
    
}
body {
    //font-family: v.$fuente_textos;
    font-size: 1.6rem;
    min-height: 100%;
    background-color: v.$blancoTmk;
    height: 100%;
    font-family: 'Roboto', sans-serif;
	overflow-x: hidden;
}
.contenedor {
    width: 95%;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
}
.contenedor-sm {
    max-width: 70rem;
    margin: 0 auto;
}
a {
    text-decoration: none;
}
img {
    max-width: 100%;
}
.inpt{

    font-size: 1.6rem;
}

