// Fuentes
$fuente_headings: 'Inter', sans-serif;
$fuente_textos: 'Open Sans', sans-serif;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;

// Colores
$cyan: #0891B2;
$azul: #2563EB;
$indigo: #4338CA;
$morado: #7C3AED;
$naranja:#F59E0B;
$rosa: #DB2777;

$blanco: #FFFFFF;
$fondoHeader:#1C2142;
$fondoFooter:#232931;
$blancoTmk:#f2f2f2;
$gris: #6b7280;
$grisOscuro: #4B5563;
$grisClaro: #212529;
$negro: #000;

$rojo: rgb(169, 0, 0);
$verde: rgb(0, 120, 0);

$separacion: 5rem;
$barra-lateral:#355764;