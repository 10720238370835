@use '../base/variables' as v;
@use '../base/mixins' as m;


.inicio{
	min-width: 100%;
	height: 100vh;
	margin-top: 60px;
	background-image: url("../img/carousel1.jpg");
	background-size: cover;
	background-repeat: no-repeat;
}
.card{
    .info{
        font-size: .8em;
    } 
	
}
/*.card{
	min-width:250px;
	padding-top: -50;
}*/
.card-body ul svg{
	margin-right: 10px;
}
.contacto{
	background:#f2f2f2;
	border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #cbcbcb;
    .titulo{
        color: #003855;
        font-family: 'Open Sans',sans-serif;
        font-weight: 300;
        font-size: 40px;
        margin-bottom: 40px;
        text-align: center;
    }
    .formulario{
        
        input[type="text"],
        input[type="email"],
        textarea{
            padding: 15px;
            border: none;
            font-family:'Open Sans',sans-serif;
        }
        textarea{
            height: 150px;
            min-height: 150px;
            max-height: 250px;
        }
        .boton{
            background: #3da5e2;
            border: none;
            padding: 7px 30px;
            color: #fff;
            font-family: 'Open Sans',sans-serif;
            cursor: pointer;
            width: auto;
            border-radius: 3px;
        }
    } 
    
    
}
