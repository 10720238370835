@use 'base/variables' as v;
@use 'base/mixins' as m;

.tablaReserva{
    th{
        font-weight: bold;
        h3{
            text-transform: uppercase;
            margin-top: 2rem;
        }
        :first-child{
            padding-top: 1rem;
            display: flex;
            justify-content: center;
            font-weight: bold;
        }
    }
    td{
        padding: 1rem;
        font-size: 2rem;
        span{
            font-weight: bold;
        }
        h1,h2,h3,h4{
            text-transform: uppercase;
            margin: 2rem;
        }
    }
}
